<template>
    <div class="v_register g-flex-column g-flex-align-center g-flex-justify-center">
        <!-- <div class="v-register-logo g-flex-align-center">
            <img src="/img/logo2.png" alt="">
            <p>{{$global.config.web_title}}</p>
        </div> -->
        <div class="v-form-box g-flex-align-center g-flex-justify-center">
            <img :src="imgUrl" alt="">
            <el-form ref="form" :model="form" :rules="rules" class="v-form g-flex-column g-flex-align-start">
              <div class="v-form-head-box g-flex-align-center">
                <h3 class="v-register-title">用户注册</h3>
                <p class="v-form-head-to-login">已有账号，<span @click="$router.replace({ name: 'login' })">直接登录</span></p>
              </div>

                <p class="v-register-tip">如果您有任何疑问，请随时与我们联系，给予你最便捷的生活态度</p>

                <el-form-item prop="mobile">
                    <el-input type="text" placeholder="请输入手机号" v-model="form.mobile" autocomplete="off" prefix-icon="iconfont iconphone"/>
                </el-form-item>

                <el-form-item prop="mobile_code" class="v-register-item-send-code">
                    <el-input type="text" placeholder="请输入验证码" v-model="form.mobile_code" autocomplete="off" prefix-icon="iconfont iconyanzhengma"/>
                    <div class="v-register-item-send-code-btn g-flex-align-center g-flex-justify-center" @click="getCode"><span>{{sendCodeText}}</span></div>
                </el-form-item>

                <el-form-item prop="password">
                    <el-input :type="isPwdType ? 'password': ''"  placeholder="请设置登录密码(6-16位)" v-model="form.password" autocomplete="off">
                         <i slot="prefix" class="iconfont iconmima"></i>
                         <i slot="suffix" @click="toggleType" class="iconfont" :class="isPwdType ? 'iconyanjing-bukejian-copy' : 'iconmimakejianguanbi'"></i>
                    </el-input>
                </el-form-item>
                
                <el-form-item prop="password2">
                    <el-input :type="isPwdTypeTwo ? 'password': ''"  placeholder="请确认登录密码" v-model="form.password2"  autocomplete="off">
                         <i slot="prefix" class="iconfont iconmima"></i>
                         <i slot="suffix" @click="toggleTypeSecond" class="iconfont" :class="isPwdTypeTwo ? 'iconyanjing-bukejian-copy' : 'iconmimakejianguanbi'"></i>
                    </el-input>
                </el-form-item>

                <el-form-item prop="qq">
                    <el-input type="text"  placeholder="请输入QQ号(选填)" v-model="form.qq"  autocomplete="off">
                         <i slot="prefix" class="iconfont iconQQ"></i>
                    </el-input>
                </el-form-item>

                <el-form-item prop="wechat">
                    <el-input type="text"  placeholder="请输入微信号(选填)" v-model="form.wechat" autocomplete="off">
                         <i slot="prefix" class="iconfont iconweixin2"></i>
                    </el-input>
                </el-form-item>

                <el-form-item prop="tcode">
                    <el-input type="text"  placeholder="请输入邀请码(必填)" v-model="form.tcode" @keyup.enter.native="register" autocomplete="off">
                         <i slot="prefix" class="iconfont iconyaoqingma"></i>
                    </el-input>
                </el-form-item>

     
                <el-button type="primary" @click="register">快速注册</el-button>
            </el-form>
        </div>

       <concat-us-pop ref="ConcatUsPop" v-if="$global.adminUser.type == 2" :kefuInfo="$global.config.kefu"/>
    </div>
</template>

<script>
// if(!(/^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/.test(this.form.mobile))) return this.$toast('手机号码有误，请重试'); 
    import { apiReg, apiSendCode, apiGetDesktop } from '@/utils/api.js'
    import ConcatUsPop from "@/components/ConcatUsPop.vue";
    export default {
        components: { ConcatUsPop },
        name: 'Login',
        data() {
            return {
                imgUrl: '/img/register.png',
                
                isPwdType: true,

                isPwdTypeTwo: true,
                form: {
                    mobile: '',
                    mobile_code: '',
                    password: '',
                    password2: '',
                    tcode: '',
                    qq: '',
                    wechat: ''
                },

                // 表单验证，需要在 el-form-item 元素中增加 prop 属性
                rules: {
                    mobile: [
                        {required: true, message: '手机号不可为空', trigger: 'blur'}
                    ],
                    mobile_code: [
                        {required: true, message: '验证码不可为空', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '密码不可为空', trigger: 'blur'}
                    ],
                    password2: [
                        {required: true, message: '密码不可为空', trigger: 'blur'}
                    ],
                    tcode:[
                        {required: true, message: '推广码不可为空', trigger: 'blur'}
                    ]
                },

               sendCodeText: '获取验证码',
               defaultCodeText:'重新获取',

            }
        },
        created() {
            if(this.$route.query.tcode) this.form.tcode = this.$route.query.tcode
            this.apiGetDesktopHandel()
        },
        methods: {
            //  获取登录界面
            async apiGetDesktopHandel() {
                let loading = this.$loading({
                    text: '拼命加载中',
                    background: 'rgba(0, 0, 0, 0.4)'
                })
                const { success, data } = await apiGetDesktop().catch(() => {  loading.close() })
                loading.close()
                if (!success) return;
                this.$global.adminUser.type = data.data.type
                this.$global.config.kefu = data.data.kefu
                document.title = data.data.title
            },
            toggleType() {
                this.isPwdType = !this.isPwdType
            },
            toggleTypeSecond() {
                this.isPwdTypeTwo = !this.isPwdTypeTwo
            },
            // 获取验证码
            getCode() {
              if(!this.form.mobile)  return this.$message.error('请输入手机号'); 
              if(!(/^1\d{10}$/.test(this.form.mobile))) return this.$message.error('手机号码有误，请重试')
            //   if(!(/^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/.test(this.form.mobile))) return this.$message.error('手机号码有误，请重试'); 
              if(this.timer) return
              this.apiSendCodeHandel()
            },

            // 发送验证码
            async apiSendCodeHandel() {
              const { success, data } = await apiSendCode({ mobile: this.form.mobile })
              if(!success) return
              let timeNum = 60
              this.$message.success(data.msg)
              this.timer = setInterval(() => {
                timeNum --
                this.showNum = timeNum === 0 ? false : true
                this.sendCodeText = timeNum === 0 ? this.defaultCodeText : timeNum + 's'
                if(timeNum === 0) {
                  clearInterval(this.timer)
                  this.timer = 0
                }
              }, 1000)
            },


            register() {
                if(this.form.password !== this.form.password2) return this.$message.error('请保证两次密码输入一致!')
                // 表单绑定验证功能
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        if(!(/^1\d{10}$/.test(this.form.mobile))) return this.$message.error('手机号码有误，请重试'); 
                        // if(!(/^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/.test(this.form.mobile))) return this.$message.error('手机号码有误，请重试'); 
                        const loading = this.$loading()
                        const { success, data } = await apiReg(this.form).catch(()=> { loading.close() })
                        loading.close()
                        if(!success) return 
                        this.$message.success(data.msg)
                        setTimeout(() => {
                          this.$router.replace('/')
                        }, 500)
                    } else {
                        return false;
                    }
                })
            }
        }
    }
</script>

<style lang="scss">
    .v_register {
        background: url("/img/login_bg.jpg") no-repeat;
        background-position: center;
        background-size: cover;
        width:100%;
        height:100%;
        .v-register-logo {
            padding-bottom: 16px;
            p {
                padding-left: 6px;
                font-size: 40px;
                color: #007AFF;
            }
        }
        .v-form-box {
            background-color: rgba($color: #fff, $alpha: 0.6);
            border: 1px solid #DCDFE6;
            width: 1300px;
            height: 600px;
            border-radius: 20px;
            img {
                border-radius: 20px 0 0 20px;
                width: 800px;
                height: 600px;
                object-fit: cover;
                vertical-align: middle;
            }
            
            .v-form {
              flex: 1;
              padding: 0 40px;
                .el-button {
                    background: #007AFF;
                    width: 90%;
                }
                .el-form-item {
                    width: 90%;
                    .el-input {
                        color: #B5B5B5;
                        .el-input__inner {
                            border: 1px solid #7cbbff;
                            border-radius: 8px;
                            padding-left: 40px;
                            padding-right: 40px;
                        }
                        .el-input__prefix {
                            i {
                                font-size: 26px;
                            }
                        }
                        .el-input__suffix {
                            i {
                                font-size: 26px;
                                cursor: pointer;
                            }
                        }
                    }
                }
                .v-register-item-send-code {
                  display: flex;
                  align-items: center;
                  .el-form-item__content {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    .el-input {
                      flex: 3;
                    }
                  }
                  .v-register-item-send-code-btn {
                    cursor: pointer;
                    flex: 1;
                    margin-left: 20px;
                    min-width: 100px;
                    border-radius: 8px;
                    border: 1px solid#7cbbff;
                    color: #5794ff;
                    background: #fff;
                    font-size: 14px;
                    user-select: none;
                  }

                }

                .v-register-title {
                    font-size: 28px;
                    text-align: left;
                    font-weight: normal;
                    color: #007AFF;
                    margin: 0;
                    padding: 0;
                    padding-bottom: 20px;
                }
                .v-register-tip {
                    font-size: 12px;
                    color: #B5B5B5;
                    padding-bottom: 28px;
                }
            }




            .v-form-head-box {
              width: 100%;
              .v-register-title {
                flex: 1;
              }
              .v-form-head-to-login {
                color: #999;
                font-size: 14px;
                span {
                  padding-left: 4px;
                  padding-right: 4px;
                  color: #007AFF;
                  cursor: pointer;
                }
              }
            }



        }
    }
</style>